"use client";

import { BaseInput } from "../BaseInput/BaseInput.component";
import { withClearButton } from "../WithClearButton/WithClearButton.component";
import { withFloatingLabel } from "../WithFloatingLabel/WithFloatingLabel.component";
import { withValidation } from "../WithValidation/WithValidation.component";
import { inputConfig } from "@/components/Input/input.config";
import { useDebouncedCallback } from "@/utilities/with-debounce";
import { forwardRef, useEffect, useState } from "react";
import type { ChangeEvent, ForwardedRef, InputHTMLAttributes, ReactNode } from "react";
const InputComponent = withClearButton(withFloatingLabel(withValidation(BaseInput)));
type DefaultInputProps = InputHTMLAttributes<HTMLInputElement> & {
  readonly children?: ReactNode;
  readonly description?: string;
  readonly onClear?: () => void;
  readonly debounceMilliseconds?: number;
};
function DefaultInputComponent({
  children,
  debounceMilliseconds,
  defaultValue,
  onChange = () => "",
  onClear,
  ...defaultInputProps
}: DefaultInputProps, ref: ForwardedRef<HTMLInputElement>) {
  const {
    millisecondsUntilFinishedTyping
  } = inputConfig();
  const [value, setValue] = useState<string>(String(defaultValue ?? ""));
  const debouncedOnChange = useDebouncedCallback(onChange, debounceMilliseconds ?? millisecondsUntilFinishedTyping);
  useEffect(() => {
    setValue(String(defaultValue ?? ""));
  }, [defaultValue]);
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    debouncedOnChange(event);
  }
  function reset() {
    setValue("");
    onClear?.();
  }
  return /* eslint-disable react/jsx-props-no-spreading -- we have to */<div className="relative isolate" data-sentry-component="DefaultInputComponent" data-sentry-source-file="DefaultInput.component.tsx">
      <InputComponent {...defaultInputProps} defaultValue={value} onChange={handleChange} onClear={reset} ref={ref} value={value} data-sentry-element="InputComponent" data-sentry-source-file="DefaultInput.component.tsx">
        {children}
      </InputComponent>
    </div>
  /* eslint-enable */;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- components ought to be capitalized
export const DefaultInput = forwardRef(DefaultInputComponent);